<template>
  <v-container>
    <v-card v-if="library" class="my-4">
      <v-card-text>
        <!-- Informations de la bibliothèque -->
        <v-row>
          <!-- Nom -->
          <v-col cols="6">
            <strong>Name:</strong>
            <v-text-field
              v-model="editableLibrary.name"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.name }}
            </template>
          </v-col>

          <!-- Description -->
          <v-col cols="6">
            <strong>Description:</strong>
            <v-text-field
              v-model="editableLibrary.description"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.description }}
            </template>
          </v-col>

          <!-- Email -->
          <v-col cols="6">
            <strong>Email:</strong>
            {{ library.email }}
          </v-col>

          <!-- Téléphone -->
          <v-col cols="6">
            <strong>Phone Number:</strong>
            <v-text-field
              v-model="editableLibrary.numTel"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.numTel }}
            </template>
          </v-col>

          <!-- Ville -->
          <v-col cols="6">
            <strong>City:</strong>
            <v-text-field
              v-model="editableLibrary.city"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.city }}
            </template>
          </v-col>

          <!-- Code Postal -->
          <v-col cols="6">
            <strong>Postal Code:</strong>
            <v-text-field
              v-model="editableLibrary.postalCode"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.postalCode }}
            </template>
          </v-col>

          
          <!--  -->
          <v-col cols="6">
            <strong>Limite livre:</strong>
            <v-text-field
              v-model="editableLibrary.limitBook"
              v-if="isEditing"
              variant="outlined"
            ></v-text-field>
            <template v-else>
              {{ library.limitBook }}
            </template>
          </v-col>
          </v-row>
            <!-- afficher Image  -->
           
            <v-row v-if="!isEditing" class="my-4">
              
              <v-col cols="12" sm="6" v-if="library.fileUrl || library.fileDBId">
  <v-card
    class="pa-3 background-card"
    outlined
    :style="{
      backgroundImage: `url(${library.fileDBId ? getImageUrl(library.fileDBId) : library.fileUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '200px'  
    }"
  >
    <v-card-title class="d-none">Primary Image:</v-card-title>
  </v-card>
</v-col>

 

  

  <v-col cols="12" sm="6" v-if="library.fileBannerUrl|| library.fileBannerDBId">
    <v-card class="pa-3 background-card"
     outlined :style="{
       backgroundImage: `url(${library.fileBannerDBId ? getImageUrlBanner(library.fileBannerDBId) : library.fileBannerUrl})`,
       backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '200px'  
    }"
  >
 
      <v-card-title class="d-none">Banner Image:</v-card-title>
      
    </v-card>
    
  </v-col>
</v-row>

        <!-- Image Upload -->
        <v-row v-if="isEditing">
          <!-- Primary Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesId"
              v-model="images"
              :data-images="images"
              name="images"
              drag-text="Please add an image!"
              browse-text="Browse image"
              primary-text="Primary image"
              mark-is-primary-text="Set default image"
              :min-image="1"
              :max-image="1"
              :multiple="false"
              :showEdit="false"
              popup-text="Description default image"
              drop-text="Drag and drop"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoading && uploadPercentage < 100"
              :value="uploadPercentage"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
            </v-progress-linear>
          </v-col>

          <!-- Banner Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesIdBanner"
              v-model="bannerImage"
              :data-images="bannerImage"
              name="bannerImage"
              drag-text="Please add a banner image!"
              browse-text="Browse banner image"
              primary-text="Banner image"
              :min-image="1"
              :max-image="2"

              :multiple="false"
              :loading="load"
              :showEdit="false"
              popup-text="Description banner image"
              drop-text="Drag and drop"
              @upload-success="uploadImageSuccessBanner"
              @before-remove="beforeRemoveBanner"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoadingBanner && uploadPercentageBanner < 100"
              :value="uploadPercentageBanner"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentageBanner) }}%</strong>
            </v-progress-linear>
          </v-col>
        </v-row>
       

        <!-- Buttons -->
        <v-row justify="space-between" class="mt-4">
  <!-- Modifier Button -->
  <v-col cols="auto" v-if="!isEditing">
    <v-btn @click="enableEditing(library)" color="info" class="submit-btn">
      Modifier
    </v-btn>
  </v-col>

  <!-- Sauvegarder  Button -->
  <v-col cols="auto" v-if="isEditing" class="d-flex justify-end">
    <v-btn @click="confirmChanges" color="info" class="mr-2">
      Sauvegarder
    </v-btn>

      <!--  Annuler Button -->

    <v-btn @click="cancelEditing" color="error">
      Annuler
    </v-btn>
  </v-col>
</v-row>

      </v-card-text>
    </v-card>
   <!-- Payment Data Card -->
   <v-card class="my-4">
      <v-card-title>Données de paiement</v-card-title>

      <v-form ref="form" @submit.prevent="submitPaymentData">
        <v-card class="mb-4">
          <v-card-title>PayPal</v-card-title>
          <v-card-text>
          <v-text-field
            v-model="paymentData.paypalClientData"
            label="Données du client PayPal"
            outlined
            required
            class="form-field"
          ></v-text-field>
          <v-text-field
            v-model="paymentData.paypalSecretKey"
            label="Clé secrète PayPal"
            outlined
            required
            class="form-field"
          ></v-text-field>
        </v-card-text>
        </v-card>

        <v-divider class="my-4"></v-divider>

        <v-card class="mb-4">
          <v-card-title>Stripe</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="paymentData.stripePublicKey"
            label="Clé publique Stripe"
            outlined
            required
            class="form-field"
          ></v-text-field>
          <v-text-field
            v-model="paymentData.stripeSecretKey"
            label="Clé secrète Stripe"
            outlined
            required
            class="form-field"
          ></v-text-field>
        </v-card-text>
        </v-card>

        <v-btn type="submit" color="info" class="submit-btn">Enregistrer</v-btn>
      </v-form>

      <v-alert
        v-if="responseMessage"
        type="success"
        dismissible
        class="response-alert"
      >
        {{ responseMessage }}
      </v-alert>
    </v-card>

    <!-- Alert for save operation -->
    <v-alert
      v-if="alertMessage"
      :type="alertType"
      dismissible
      class="my-4"
    >
      {{ alertMessage }}
    </v-alert>
    
  </v-container>
</template>



<script>
import axios from 'axios';
import Constant from "@/utils/constants";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import store from "../store/image";
export default {
  components: { VueUploadMultipleImage },

  data() {
    return {
      // departments: [],
      headers: [
        { text: 'Numéro', value: 'id' },
        { text: 'Nom', value: 'name' },
        { text: 'Preposition', value: 'nameWithPreposition' },
        { text: 'Livraison', value: 'livraison' }
      ], 
      library: {
        id: null,
        name: '',
        description: '',
        email: '',
        numTel: '',
        city: '',
        postalCode:'',
        limitBook:'',
        latitude: '',
        longitude: '',
        address: '',
        // fileId: null,
        // fileBannerId: null,
        fileDBId: null,
        fileBannerDBId: null,
        fileUrl: '',
        fileBannerUrl: '',
        ownerId: null,
      },
      paymentData: {
          paypalClientData: "",
          paypalSecretKey: "",
          stripePublicKey: "",
          stripeSecretKey: "",
        },
      alertMessage: "",  
      alertType: "",
      responseMessage: "",
      images: [],
      bannerImage:[],
      bannerImageUpload:[],
      imagesUpload:[],
      load: false,
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      showLoading: false,
      showLoadingBanner: false,
      loading: false,
      valid: false,
      imagesId: "1",
      imagesIdBanner: "0",
      imageIdBanner: null,
      imageId: null,
      editableLibrary: {}, // Contient les données modifiables
      isEditing: false, //  activer/désactiver le mode édition
      loading: false,
      ownerId: null,
    };
  },
  created() {
    const storedOwnerId = localStorage.getItem('ownerId');
    if (storedOwnerId) {
      this.ownerId = parseInt(storedOwnerId, 10);
      this.fetchLibraryDetails(); // Récupérer les détails de la bibliothèque au chargement du composant
      this.fetchPaymentData(); // Load payment data based on ownerId
      // this.fetchDepartments();
    } else {
      // console.error('Owner ID not found in local storage');
    }
  },
  methods: {
    getImageUrl(fileDBId) {
      return `${Constant.QORANI}fileDB/files/${fileDBId}`;
    },
    getImageUrlBanner(fileBannerDBId) {
      return `${Constant.QORANI}fileDB/files/${fileBannerDBId}`;
    },

  fetchPaymentData() {
        if (this.ownerId === null) {
          // console.error("Owner ID is not set.");
          return;
        }

        fetch(Constant.QORANI + `library/library/getByOwnerId/${this.ownerId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(data => {
        // Check if the response has the expected nested property
        if (data && data.paymentDataResponse) {
          const paymentData = data.paymentDataResponse;
          this.paymentData = {
            paypalClientData: paymentData.paypalClientData || "",
            paypalSecretKey: paymentData.paypalSecretKey || "",
            stripePublicKey: paymentData.stripePublicKey || "",
            stripeSecretKey: paymentData.stripeSecretKey || ""
          };
        } else {
          //console.error("Unexpected response format:", data);
          this.responseMessage = "Format de réponse inattendu.";
        }
        })
      .catch(error => {
        // console.error("Error:", error);
        this.responseMessage = "An error occurred while fetching payment data.";
      });
  },
  submitPaymentData() {
          if (this.ownerId === null) {
            this.responseMessage = "L'ID du propriétaire n'est pas défini.";
            return;
          }
    
          fetch(Constant.QORANI + `user/editPaymentData/${this.ownerId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.paymentData),
          })
            .then(response => response.json())
            .then(data => {
              this.responseMessage = "Données de paiement mises à jour avec succès !";
            })
            .catch(error => {
              // console.error("Error:", error);
              this.responseMessage = "Failed to update library. Please try again.";
            });
  },
  async confirmChanges() {
          if (!this.library) return;
          
          try {
            this.loading = true;
            // Envoyer les données modifiées
            const imageId = store.state.imageId;
      const imageIdBanner = store.state.imageIdBanner;
            const libraryData = {
              
              ...this.editableLibrary, 
              fileDBId:imageId,
              fileBannerDBId:imageIdBanner,
              ownerId: this.ownerId, 
          };
            const response = await axios.post(Constant.QORANI+`library/library/edit/${this.library.id}`, libraryData);

            this.library = { ...this.editableLibrary };
           console.log("aaaaaaaaaaaaaaaa",libraryData);
            this.isEditing = false; 
            alert("Librairie mise à jour avec succès !");
            window.location.reload(); 

          } catch (error) {
            
            alert("Échec de la mise à jour de la bibliothèque. Veuillez réessayer.");
          } finally {
            this.loading = false;
          }
      },
  async fetchLibraryDetails() {
        try {
          const response = await axios.get(Constant.QORANI+`library/library/getByOwnerId/${this.ownerId}`);
          this.library = response.data;
          this.editableLibrary = { ...this.library }; // Initialiser editableLibrary avec les données actuelles
          // console.log("Library ID:", this.library.id);
        } catch (error) {
          // console.error('Failed to fetch library details:', error);
        }
  },

  enableEditing(library) {

        event.preventDefault();
        this.library = { ...library }; 
        this.isEditing = true;
        this.images = [{
      name: "",
      path: this.getImageUrl(library.fileDBId),
      highlight: 1,
      default: 1,
    }];
    
    this.bannerImage = [{
      name: "",
      path: this.getImageUrlBanner(library.fileBannerDBId),
      highlight: 1,
      default: 1,
    }];
  },

  cancelEditing() {
          this.editableLibrary = { ...this.library }; // Réinitialiser les données modifiables
          this.isEditing = false;
  },
  handleImagesUpdated(images) {
          const uploadedImage = images[0];
          // console.log("Uploaded image:", uploadedImage);
  },
  async uploadImageSuccess(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccess", { formData, index, fileList });
        const imageId = store.state.imageId;
        console.log("Image id :", imageId);
        this.library.fileDBId = imageId;
      } catch (error) {
        console.error("Failed to upload image:", error);
      }},
    

  handleBannerImageUpdated(bannerImage) {
      const uploadedImage = bannerImage[0];
      // console.log("Uploaded image:", uploadedImage);
      },
      async uploadImageSuccessBanner(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccessBanner", { formData, index, fileList });
        const imageIdBanner = store.state.imageIdBanner;
        this.library.fileBannerDBId = imageIdBanner; // Update library with new banner image ID

        console.log("Image idBanner :", imageIdBanner);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },
      
  beforeRemoveBanner(index, done, fileList) {          
          const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
          
          if (confirmation) {
            this.bannerImage = [];
            this.library.fileBannerDBId = null; 
             this.$emit('input', this.bannerImage);
            done(); // Appel de done() pour continuer
          }
  },
  beforeRemove(index, done, fileList) {
        const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
        if (confirmation) {
          this.images = [];
          this.library.fileDBId = [];
          this.$emit('input', this.images);
          
          done(); // Appel de done() pour continuer
        } 
  },
  

},
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
}

.form-field {
  margin-bottom: 20px;
}

.background-card {
  background-size: cover;
  background-position: center;
  color: white; /* Change text color if needed for better contrast */
  height: 200px; /* Adjust the height according to your needs */
  position: relative;
}

.background-card .v-card-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better text readability */
  padding: 5px;
  border-radius: 4px;
}

.v-card {
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.v-img {
  margin-top: 20px;
}

.payment-form {
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-field {
  margin-bottom: 20px;
}

.submit-btn {
  display: block;
  margin-top: 20px;
}

.response-alert {
  margin-top: 20px;
}


.v-data-table {
  margin-top: 20px;
}
</style>